import "./Analytics.css";

import "bootstrap/dist/css/bootstrap.min.css";

import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HeaderMenu from "../../Components/HeaderMenu";

function Analytics() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  if (!isAuthenticated) {
    // Redirect to the login page if not authenticated
    // You can use the 'useNavigate' hook or 'Redirect' component from React Router
    return <Navigate to="/login" />;
  } else {
    return (
      <div className="container">
        <HeaderMenu />

        <div className="row">
          <h2>Analytics page is under contruction!</h2>
        </div>
      </div>
    );
  }
}

export default Analytics;
