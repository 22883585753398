// LoginForm.js
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../authSlice";
import { useNavigate, Link } from "react-router-dom";
import { authenticateUser } from "../Services/AwsService"; // Import the AWS service
import "./LoginForm.css";
import "./ConfirmationCodeForm";
import ConfirmationCodeForm from "./ConfirmationCodeForm";
import { getToken } from "../Services/nftbreweryService";

function LoginForm() {
  // Define constants for labels, button text, etc.
  const emailLabel = "E-mail Address";
  const passwordLabel = "Password";
  const buttonText = "Login";

  // State variables for form input, error handling, etc.
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isUserConfirmed, setUserConfirmed] = useState(false);
  const [error, setError] = useState("");
  const [errorTerms, setErrorforTerms] = useState("");
  const [isTermsChecked, setTermsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationCode, setShowConfirmationCode] = useState(false);

  // Hooks for navigation and Redux
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Event handler for checkbox change
  const handleCheckboxChange = () => {
    setTermsChecked(!isTermsChecked);
  };

  // Event handler for toggling password visibility
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  // Event handler for confirmation code button click
  const handleConfirmClick = () => {
    setUserConfirmed(false);
    setShowConfirmationCode(true);
  };

  // Event handler for login button click
  const handleLogin = async () => {
    // Validation checks
    if (username === "") {
      setError("Email cannot be empty.");
      setErrorforTerms("");
      return;
    }
    if (password === "") {
      setError("Password cannot be empty.");
      setErrorforTerms("");
      return;
    }
    if (!isTermsChecked) {
      setErrorforTerms("Terms and conditions must be agreed to continue.");
      setError("");
      return;
    }

    try {
      // Call the AWS Cognito authentication function
      const result = await authenticateUser(username, password);

      // Handle the authentication result
      if (!result.isConfirmed && !result.isAuthenticated) {
        console.log("User is not confirmed.");
        setError("User confirmation is required to log in.");
        // Handle logic for obtaining the confirmation code
      } else if (result.isConfirmed && result.isAuthenticated) {
        setUserConfirmed(true);
        // Call the TokenService to get the token
        try {
          const { rawToken, decryptedToken } = await getToken();

          // Check if the token is not null and the domain is correct
          if (
            decryptedToken !== null &&
            decryptedToken.frontend_domain_url === "https://demo.swiirl.com"
          ) {
            // Dispatch the login action with additional information
            dispatch(
              login({
                token: rawToken,
                decryptedToken: decryptedToken,
                email: username,
              })
            );
            // Navigation to the gallery
            navigate("/gallery");
          } else {
            // Handle the case where the token retrieval failed or the domain is incorrect
            console.error("Token is empty or domain is incorrect");
          }
        } catch (tokenError) {
          console.error("Error while getting or decrypting token:", tokenError);
          // Handle error as needed
        }
      } else {
        setUserConfirmed(true);
        setError("Invalid email or password.");
        setErrorforTerms("");
      }
    } catch (error) {
      console.error("Error during authentication:", error);
      // Handle error as needed
    }
  };

  // Return the JSX for the component
  return (
    <div className="loginform">
      {!isUserConfirmed && showConfirmationCode ? (
        <ConfirmationCodeForm
          onCallback={() => setShowConfirmationCode(false)}
        />
      ) : (
        <>
          <div className="group">
            <div className="label">{emailLabel}</div>
            <input
              type="text"
              className="rectangle"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="overal-1">
            <div className="group">
              <div className="label">{passwordLabel}</div>
              <div className="input-container">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="rectangle"
                />
                <img
                  className="icon-olor-instance"
                  alt="Icon olor"
                  src="icon-olor-1.svg"
                  onClick={handleTogglePassword}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>

          <div className="forgot-password-link">
            <Link className="text-wrapper-6" to="/forgot-password">
              Forgot your Password?
            </Link>
          </div>

          <button className="button-link" onClick={handleConfirmClick}>
            Do you have the confirmation code?
          </button>

          {error && <p style={{ color: "red" }}>{error}</p>}

          <div className="checkbox">
            <div className="group-2">
              <input
                type="checkbox"
                checked={isTermsChecked}
                onChange={handleCheckboxChange}
                className="rectangle-2"
              />
              <label className="i-agree-to-all-the-t">
                <span className="span">I agree to all the </span>
                <a
                  href="https://www.swiirl.io/terms-and-conditions"
                  className="text-wrapper-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  Term of conditions
                </a>
                <span className="span"> &amp; </span>
                <a
                  href="https://www.swiirl.io/privacy-policy"
                  className="text-wrapper-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </label>
            </div>
          </div>

          {errorTerms && <p style={{ color: "red" }}>{errorTerms}</p>}

          <button onClick={handleLogin} className="frame">
            <div className="text-wrapper">{buttonText}</div>
          </button>
        </>
      )}
    </div>
  );
}

export default LoginForm;
