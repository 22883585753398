import React, { useState, useEffect } from "react";
import "./Settings.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Nav, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import HeaderMenu from "../../Components/HeaderMenu";
import { fetchWalletAddress } from "../../Services/nftbreweryService";
import { changePassword } from "../../Services/AwsService"; // Import the AWS service

function Settings() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [activeTab, setActiveTab] = useState("profile");
  const email = useSelector((state) => state.auth.email);
  const [walletAddress, setWalletAddress] = useState("");
  const token = useSelector((state) => state.auth.token);
  const decryptedToken = useSelector((state) => state.auth.decryptedToken);
  const [changePasswordMessage, setChangePasswordMessage] = useState(null);
  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [redirecting, setRedirecting] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const startCountdown = () => {
    setRedirecting(true);

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Redirect after 5 seconds
    setTimeout(() => {
      clearInterval(countdownInterval);
      navigate("/login");
    }, 5000);
  };

  const handleChangePassword = async () => {
    try {
      // Call the changePassword function and await the result
      const result = await changePassword(email, currentPassword, newPassword);

      // Handle the result after successfully changing the password
      console.log("Password changed successfully:", result);
      // Update success message
      setChangePasswordMessage("Password changed successfully");

      // Start the countdown for auto-redirect
      startCountdown();
    } catch (error) {
      // Handle errors that may occur during the changePassword operation
      console.error("Error changing password:", error);

      // Update failure message
      setChangePasswordMessage("Failed to change password");
    }
  };

  useEffect(() => {
    // Check token expiration when component mounts or when token changes
    if (isAuthenticated && token) {
      // Check if the token is expired
      if (decryptedToken && decryptedToken.exp * 1000 < Date.now()) {
        // Token is expired, redirect to login
        navigate("/login");
      }
    }

    // Fetch wallet address when the component mounts
    const fetchData = async () => {
      try {
        const walletAddress = await fetchWalletAddress(email, token);
        setWalletAddress(walletAddress);
      } catch (error) {
        console.error("Error fetching wallet address:", error);
      }
    };

    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated, email, token, decryptedToken, navigate]);
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  } else {
    return (
      <div className="container container-settings">
        <HeaderMenu />

        <Tab.Container
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(key)}
        >
          <Nav variant="tabs" className="mb-4 mt-4">
            <Nav.Item>
              <Nav.Link eventKey="profile">Profile</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="change-password">Change Password</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="profile">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-info">
                    <h4>Email Address:</h4>
                    <p>{email}</p>
                  </div>
                  <div className="profile-info">
                    <h4>Wallet Address:</h4>
                    <p>{walletAddress}</p>
                  </div>
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="change-password">
              <div className="group">
                <div className="label">Email Address</div>
                <input
                  type="email"
                  value={email}
                  readOnly
                  className="rectangle"
                />
              </div>

              <div className="overal-1">
                <div className="group">
                  <div className="label">Current Password</div>
                  <div className="input-container">
                    <input
                      type={showPassword ? "text" : "password"}
                      value={currentPassword}
                      onChange={(e) => {
                        setCurrentPassword(e.target.value);
                      }}
                      className="rectangle"
                    />
                    {/* Toggle password visibility icon */}
                    <img
                      className="icon-olor-instance"
                      alt="Icon olor"
                      src="icon-olor-1.svg"
                      onClick={handleTogglePassword}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
              <div className="overal-1">
                <div className="group">
                  <div className="label">New Password</div>
                  <div className="input-container">
                    <input
                      type={showPassword ? "text" : "password"}
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                      className="rectangle"
                    />
                    {/* Toggle password visibility icon */}
                    <img
                      className="icon-olor-instance"
                      alt="Icon olor"
                      src="icon-olor-1.svg"
                      onClick={handleTogglePassword}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
              <button onClick={handleChangePassword} className="frame">
                <div className="text-wrapper">Change Password</div>
              </button>
              {redirecting && (
                <div className="message">
                  Auto redirecting to login in {countdown} seconds. If not
                  happening,{" "}
                  <button
                    className="button-link"
                    onClick={() => navigate("/login")}
                  >
                    Login here
                  </button>
                </div>
              )}
              {!redirecting && (
                <div style={{ color: "red" }}>{changePasswordMessage}</div>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    );
  }
}

export default Settings;
