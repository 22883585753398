// HeaderMenu.js

import React, { useState, useRef, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import LoginLogo from "./LoginLogo";
import { useDispatch } from "react-redux";
import { logout } from "../authSlice";
import MainNavigation from "./MainNavigation";
import { useSelector } from "react-redux";

import "./HeaderMenu.css";

// AvatarDropdown.js
function AvatarDropdown({ name, isOpen, onClose, position }) {
  return (
    <div
      className={`avatar-dropdown ${isOpen ? "open" : ""}`}
      style={{ top: position.top, right: position.right }}
    >
      <div className="menu-item" onClick={onClose}>
        <div className="label">Email:</div>
        <div className="value">{name}</div>
      </div>

      {/* Add more menu items as needed */}
    </div>
  );
}

function HeaderMenu() {
  const dispatch = useDispatch();

  const [isMainNavigationOpen, setIsMainNavigationOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [avatarPosition, setAvatarPosition] = useState({ top: 0, right: 0 });
  const avatarRef = useRef(null);
  const email = useSelector((state) => state.auth.email);

  const isLargeScreen = useMediaQuery({ minWidth: 769 });

  useEffect(() => {
    setIsMainNavigationOpen(isLargeScreen);
  }, [isLargeScreen]);

  useEffect(() => {
    const avatarElement = avatarRef.current;

    if (avatarElement) {
      const rect = avatarElement.getBoundingClientRect();
      setAvatarPosition({
        top: rect.bottom + window.scrollY,
        right: window.innerWidth - rect.right,
      });
    }
  }, [isDropdownOpen]);

  const handleMenuOpen = () => {
    setIsMainNavigationOpen(!isMainNavigationOpen);
  };

  const handleLogoutClick = () => {
    dispatch(logout());
  };

  const handleAvatarClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getAvatarInitial = (name) => {
    return name ? name[0].toUpperCase() : "";
  };

  return (
    <>
      <div className="row headermenu-layout">
        <div className="col-6">
          <LoginLogo />
        </div>
        <div className="col-6 headermenu-profile">
          <div className="frame-5">
            <div className="frame-6">
              <div className="text-wrapper-12"></div>
            </div>
            <div className="avatar" onClick={handleAvatarClick} ref={avatarRef}>
              {getAvatarInitial(email)}
            </div>
            <img
              className="logout"
              alt="logout"
              src="/MainNavigation/Logout.svg"
              onClick={handleLogoutClick}
            ></img>
            <img
              className="menu-open"
              alt="menu-open"
              src="/MainNavigation/menu.png"
              onClick={handleMenuOpen}
            ></img>
          </div>
        </div>
      </div>
      <AvatarDropdown
        name={email}
        isOpen={isDropdownOpen}
        onClose={() => setIsDropdownOpen(false)}
        position={avatarPosition}
      />
      {isMainNavigationOpen && <MainNavigation />}
    </>
  );
}

export default HeaderMenu;
