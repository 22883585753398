// Gallery.js
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import HeaderMenu from "../../Components/HeaderMenu";
import GalleryCard from "../../Components/GalleryCard";
import "./Gallery.css"; // Import your CSS file

import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap styles

import "../../Components/MainNavigation";
import { fetchGalleryData } from "../../Services/nftbreweryService";

function Gallery() {
  const buttonAllocateText = "Allocate";

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [checkedNames, setCheckedNames] = useState([]);

  const [assets, setAssets] = useState([]); // Initialize assets as an empty array
  const [isLoading, setIsLoading] = useState(true); // Initialize loading state

  const token = useSelector((state) => state.auth.token);
  const decryptedToken = useSelector((state) => state.auth.decryptedToken);
  const email = useSelector((state) => state.auth.email);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();

  console.log("Token in Gallery component: ", token);
  console.log("Decrypted token in Gallery component: ", decryptedToken);

  useEffect(() => {
    console.log("Effect is running");
    console.log("isAuthenticated:", isAuthenticated);
    console.log("email:", email);
    console.log("decryptedToken:", decryptedToken);
    console.log("token:", token);
    console.log("navigate:", navigate);
    if (isAuthenticated && token) {
      // Check if the token is expired
      if (decryptedToken && decryptedToken.exp * 1000 < Date.now()) {
        // Token is expired, redirect to login
        navigate("/login");
      }
    }
    const fetchData = async () => {
      try {
        // Call the service function to fetch gallery data
        const galleryData = await fetchGalleryData(email, token);

        setAssets(galleryData); // Set assets to the received data or an empty array
        setFilteredAssets(galleryData); // Update filteredAssets
        setIsLoading(false); // Update loading state
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false); // Update loading state even in case of an error
      }
    };

    fetchData();
  }, [isAuthenticated, email, decryptedToken, token, navigate]);

  const handleCheckboxChange = (cardId, isChecked) => {
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [cardId]: isChecked,
    }));

    setCheckedNames((prevNames) => {
      if (isChecked) {
        return [
          ...prevNames,
          assets.find((asset) => asset._id === cardId).jsonDetails[0]
            .jsonDetails.name,
        ];
      } else {
        return prevNames.filter(
          (name) =>
            name !==
            assets.find((asset) => asset._id === cardId).jsonDetails[0]
              .jsonDetails.name
        );
      }
    });
  };

  const handleAllocate = () => {
    alert(`Allocating: ${checkedNames.join(", ")}`);
  };

  const handleSelectAll = () => {
    setIsCheckedAll(!isCheckedAll);

    const updatedCheckboxStates = {};
    const updatedCheckedNames = [];

    assets.forEach((asset) => {
      updatedCheckboxStates[asset._id] = !isCheckedAll;
      if (!isCheckedAll) {
        updatedCheckedNames.push(asset.jsonDetails[0].jsonDetails.name);
      }
    });

    setCheckboxStates(updatedCheckboxStates);
    setCheckedNames(updatedCheckedNames);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAssets, setFilteredAssets] = useState(assets);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);

    const filtered = assets.filter(
      (asset) =>
        asset.jsonDetails[0].jsonDetails.name
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        asset.jsonDetails[0].jsonDetails.description
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        asset.jsonDetails[0].jsonDetails.image
          .toLowerCase()
          .includes(value.toLowerCase())
    );

    setFilteredAssets(filtered);
  };

  const buttonSelectAllText = isCheckedAll ? "Unselect All" : "Select All";
  const Loader = () => (
    <div className="loader-container">
      <div className="loader">Loading...</div>
    </div>
  );

  if (isLoading) {
    return <Loader />; // Show loading state while fetching data
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  } else {
    return (
      <div className="container container-gallery ">
        <HeaderMenu />
        <div className="row search-layout">
          <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
            <div className="overlap-group-wrapper">
              <div className="overlap-group-navigation">
                <input
                  className="text searchtextbox"
                  placeholder="Search by theme, school, or name"
                  value={searchTerm}
                  onChange={handleSearch}
                />
                <img
                  className="icon-search-normal"
                  alt="Icon search normal"
                  src="https://c.animaapp.com/cXWQPN4B/img/icon-search-normal@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row button-layout">
          <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center ">
            <button onClick={handleSelectAll} className="frame-button">
              <div className="text-wrapper">{buttonSelectAllText}</div>
            </button>
            <button onClick={handleAllocate} className="frame-button">
              <div className="text-wrapper">{buttonAllocateText}</div>
            </button>
          </div>
        </div>

        <div className="row justify-content-center">
          {filteredAssets.map((asset) => (
            <div
              key={asset._id}
              className="col-lg-3 col-md-4 col-sm-6 col-12 min-width-card card-alignment"
            >
              <GalleryCard
                tokenId={asset.jsonDetails[0].tokenId}
                name={asset.jsonDetails[0].jsonDetails.name}
                description={asset.jsonDetails[0].jsonDetails.description}
                walletaddress={asset.jsonDetails[0].jsonDetails.assetOwner}
                imageurl={`https://ipfs.io/ipfs/${asset.jsonDetails[0].jsonDetails.image.replace(
                  "ipfs://",
                  ""
                )}`}
                isChecked={checkboxStates[asset._id] || false}
                onCheckboxChange={(isChecked) =>
                  handleCheckboxChange(asset._id, isChecked)
                }
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Gallery;
