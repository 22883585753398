import { useState } from "react";

const useAsticaVisionAPI = () => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const callAsticaVisionAPI = async (imageUrl) => {
    setLoading(true);
    setError(null);
    setResult(null);

    const asticaAPI_endpoint = "https://vision.astica.ai/describe";
    const asticaAPI_payload = {
      tkn: "BDA606D8-6900-4269-A83B-2D62FE20887C41A9AA51-57CF-4667-BD83-8E0F779B08BC",
      modelVersion: "2.1_full",
      input: imageUrl,
      visionParams: "",
    };

    try {
      const response = await fetch(asticaAPI_endpoint, {
        method: "post",
        body: JSON.stringify(asticaAPI_payload),
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch");
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { result, error, loading, callAsticaVisionAPI };
};

export default useAsticaVisionAPI;
