import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useAsticaVisionAPI from "../../Services/fetch_asticaVision";
import useAsticaNLPAPI from "../../Services/fetch_asticaGPT";
import HeaderMenu from "../../Components/HeaderMenu";
import "./Nft.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap styles

const Nft = () => {
  const location = useLocation();
  const [chatprompt, setchatprompt] = useState(
    "write a blog post based on Image and generate tags"
  );
  const { imageUrl } = location.state;
  const [apiCalled, setApiCalled] = useState(false);
  const [copied, setCopied] = useState(false);

  const { result, error, loading, callAsticaVisionAPI } = useAsticaVisionAPI();
  const { nlpResult, nlpError, nlpLoading, callAsticaNLPAPI } =
    useAsticaNLPAPI();

  useEffect(() => {
    if (!apiCalled && imageUrl) {
      // Check if API hasn't been called and imageUrl is available
      callAsticaVisionAPI(imageUrl);
      setApiCalled(true); // Update state to indicate API call has been made
    }
  }, [apiCalled, imageUrl, callAsticaVisionAPI]);

  const copyTags = () => {
    const tagsText = result.tags.map((tag) => tag.name).join(", ");
    navigator.clipboard
      .writeText(tagsText)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 50000); // Reset copied state after 5 seconds
      })
      .catch((error) => console.error("Failed to copy tags: ", error));
  };
  const handleGetData = () => {
    if (imageUrl) {
      callAsticaVisionAPI(imageUrl);
    }
  };

  const getMetadata = (metadata) => {
    // Initialize an empty string to store the formatted metadata
    let formattedMetadata = "";

    // Loop through the metadata object and construct the formatted string
    for (const key in metadata) {
      formattedMetadata += `${key}: ${metadata[key]}, \n`;
    }

    // Set the formatted metadata as the content of the <pre> element
    return formattedMetadata;
  };

  const getModeration = (moderationData) => {
    // Define thresholds
    const adultThreshold = 0.1;
    const racyThreshold = 0.1;
    const goryThreshold = 0.1;

    // Derive moderation result based on scores and thresholds
    let moderationResult = "Safe Content"; // Default to safe

    if (
      moderationData.isAdultContent ||
      moderationData.adultScore > adultThreshold
    ) {
      moderationResult = "Adult Content Detected";
    } else if (
      moderationData.isRacyContent ||
      moderationData.racyScore > racyThreshold
    ) {
      moderationResult = "Racy Content Detected";
    } else if (
      moderationData.isGoryContent ||
      moderationData.goreScore > goryThreshold
    ) {
      moderationResult = "Gory Content Detected";
    }

    return moderationResult; // Return the moderation result
  };

  const handleProcessNLP = () => {
    if (chatprompt) {
      if (result && result.caption_GPTS) {
        callAsticaNLPAPI(result.caption_GPTS + " " + chatprompt);
      }
    }
  };

  return (
    <div className="container container-gallery-item">
      <HeaderMenu />
      <div className="row">
        <div className="col-lg-6">
          <div className="image-container d-flex flex-column align-items-center justify-content-center">
            <img alt="swiirl-logo" className="nft-image" src={imageUrl} />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="image-details">
            <div className="row">
              <div className="col-md-6">
                <div className="group-nft">
                  <div className="label">Image Caption</div>
                  <div className="tag attribute-values">
                    {result?.caption?.text || "N/A"}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="group-nft">
                  <div className="label">Metadata</div>
                  <div className="tag attribute-values">
                    {result && result.categories && result.categories.length > 0
                      ? result.categories
                          .map((category) => `${category.name}`)
                          .join(", ")
                      : "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="group-nft">
                  <div className="label">Facial Recognition</div>
                  {result && result.faces && result.faces.length > 0 ? (
                    <div className="tag attribute-values">
                      {result.faces.map((face, index) => (
                        <div key={index}>
                          Gender: {face.gender}&nbsp;&nbsp;&nbsp;&nbsp; Age:{" "}
                          {face.age}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="tag attribute-values">None</div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="group-nft">
                  <div className="label">Object Detection</div>
                  {result && result.objects && result.objects.length > 0 ? (
                    <div className="tag attribute-values">
                      {result.objects.map((obj, index) => (
                        <div key={index}>
                          {obj.name}: {Math.round(obj.confidence * 100)}%
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="tag attribute-values">None</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="group-nft">
                  <div className="label">Image Moderation</div>
                  <div className="tags-container attribute-values">
                    {result && (
                      <span className="tag attribute-values">
                        {getModeration(result.moderate)}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="group-nft">
                  <div className="label">Known Personas</div>
                  <div className="tag attribute-values">
                    {result &&
                    result.celebrities &&
                    result.celebrities.length > 0
                      ? result.celebrities
                          .map((celeb) => `Name: ${celeb.name}`)
                          .join(", ")
                      : "None"}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="group-nft">
                  <div className="label">Main Tags</div>
                  <div className="tags-container attribute-values">
                    {result &&
                      result.tags &&
                      result.tags.map((tag, index) => (
                        <span key={index} className="tag attribute-values">
                          {tag.name}
                        </span>
                      ))}
                  </div>

                  <button className="button-link" onClick={copyTags}>
                    Copy Tags
                  </button>
                  {copied && (
                    <p
                      className="attribute-values"
                      style={{ marginRight: "5px" }}
                    >
                      Tags copied!
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="group-nft">
                  <div className="label">Colour Data</div>
                  {result && (
                    <div className="tag attribute-values">
                      <p>
                        <strong>Accent Color:</strong> #
                        {result.colors.accentColor}
                      </p>
                      <p>
                        <strong>Foreground Colour:</strong>{" "}
                        {result.colors.dominantColorForeground}
                      </p>
                      <p>
                        <strong>Background Colour:</strong>{" "}
                        {result.colors.dominantColorBackground}
                      </p>
                      <p>
                        <strong>Dominant Colour:</strong>{" "}
                        {result.colors.dominantColors.join(", ")}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="group-nft">
                  <div className="label">Metadata</div>
                  <div className="tag attribute-values">
                    {result
                      ? getMetadata(result.metadata).replace(/,\s*$/, "")
                      : "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-1 mb-1 hr-shadow" />
      <div className="row">
        <div className="col-lg-6">
          <div className="group-nft">
            <div className="content-container">
              {loading && (
                <img
                  src="/Loader/Loader.svg"
                  className="loader-middle"
                  alt="Loading"
                />
              )}
              {error && <div>Error: {error}</div>}
              {result && (
                <div className="caption-details-image">
                  <div className="header-description-image">
                    Image Description [ai captured description]
                  </div>
                  <button onClick={handleGetData} className="button">
                    Generate Image Description
                  </button>
                  <div className="tag attribute-values">
                    {result.caption_GPTS}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="group-nft">
            <div className="prompt-container">
              <div className="header-description">Al Chat Prompt</div>
              <input
                type="text"
                className="prompt-input attribute-values"
                value={chatprompt}
                onChange={(e) => setchatprompt(e.target.value)}
              />
              <button onClick={handleProcessNLP} className="button">
                Generate Content
              </button>
              {nlpLoading && (
                <img
                  src="/Loader/Loader.svg"
                  className="loader-middle"
                  alt="Loading"
                />
              )}
              {nlpError && <div>Error: {nlpError}</div>}
              <div className="nlpdata">
                {nlpResult && (
                  <div>
                    <div className="header-description">
                      Social Media Post [AI driven based on image]
                    </div>
                    {nlpResult.status === "error" ? (
                      <div className="error-message-nft">
                        {
                          "Apologies for the inconvenience as the service is currently unavailable. Please try again in a few minutes."
                        }
                      </div>
                    ) : (
                      <div className="tag attribute-values">
                        {nlpResult.output}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nft;
