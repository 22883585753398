import { NavLink, useLocation } from "react-router-dom";

import "./MainNavigation.css";

function MainNavigation() {
  const { pathname } = useLocation();

  return (
    <div className="row mainnavigation-layout">
      <div className="col-lg-3 col-md-3 col-sm-12 col-12">
        <div className="frame-navigation">
          <img
            className="img-navigation"
            alt="Iconly curved"
            src={
              pathname === "/gallery" || pathname === "/gallery/"
                ? "/MainNavigation/Gallery_green.svg"
                : "/MainNavigation/Gallery.svg"
            }
          />
          <NavLink
            className={({ isActive }) =>
              isActive ? "text-wrapper-navigation" : "text-wrapper-navigation-2"
            }
            to="/gallery"
          >
            Dashboard
          </NavLink>
        </div>
      </div>

      <div className="col-lg-3 col-md-3 col-sm-12 col-12">
        <div className="frame-navigation">
          <img
            className="img-navigation"
            alt="Iconly curved"
            src={
              pathname === "/campaigns" || pathname === "/campaigns/"
                ? "/MainNavigation/Activity_green.svg"
                : "/MainNavigation/Activity.svg"
            }
          />
          <NavLink
            className={({ isActive }) =>
              isActive ? "text-wrapper-navigation" : "text-wrapper-navigation-2"
            }
            to="/campaigns"
          >
            Campaigns
          </NavLink>
        </div>
      </div>

      <div className="col-lg-3 col-md-3 col-sm-12 col-12">
        <div className="frame-navigation">
          <img
            className="img-navigation"
            alt="Iconly curved"
            src={
              pathname === "/analytics" || pathname === "/analytics/"
                ? "/MainNavigation/truck-fast_green.svg"
                : "/MainNavigation/truck-fast.svg"
            }
          />
          <NavLink
            className={({ isActive }) =>
              isActive ? "text-wrapper-navigation" : "text-wrapper-navigation-2"
            }
            to="/analytics"
          >
            Analytics
          </NavLink>
        </div>
      </div>
      <div className="col-lg-3 col-md-3 col-sm-12 col-12">
        <div className="frame-navigation">
          <img
            className="img-navigation"
            alt="Iconly curved"
            src={
              pathname === "/settings" || pathname === "/settings/"
                ? "/MainNavigation/Setting_green.svg"
                : "/MainNavigation/Setting.svg"
            }
          />
          <NavLink
            className={({ isActive }) =>
              isActive ? "text-wrapper-navigation" : "text-wrapper-navigation-2"
            }
            to="/settings"
          >
            Settings
          </NavLink>
        </div>
      </div>
    </div>
  );
}
export default MainNavigation;
