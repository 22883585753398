import Description from "../../Components/Description";
import LoginLogo from "../../Components/LoginLogo";
import LoginFooter from "../../Components/LoginFooter";
import RegistrationForm from "../../Components/RegistrationForm";

function Registration() {
  return (
    <div className="limitter">
      <div className="custom-container">
        <div className="login-content">
          <div className="nav-header">
            <div className="logo-holder">
              <LoginLogo />
            </div>
          </div>

          <Description />
          <RegistrationForm />
          <LoginFooter />
        </div>
      </div>
    </div>
  );
}
export default Registration;
