// RegistrationForm.js
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import "./RegistrationForm.css";

const RegistrationForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [showConfirmationCode, setShowConfirmationCode] = useState(false);
  const [buttonText, setButtonText] = useState("Register");
  const [showPassword, setShowPassword] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [resendLinkVisible, setResendLinkVisible] = useState(true);
  const [resendMessage, setResendMessage] = useState("");
  const [resendTimer, setResendTimer] = useState(15);
  const [isUserExists, setIsUserExists] = useState(false);
  const navigate = useNavigate();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUp = async () => {
    const poolData = {
      UserPoolId: "eu-north-1_fiC62d8OP",
      ClientId: "715e0qqr8h55p6qi3onn397hku",
    };

    const userPool = new CognitoUserPool(poolData);

    const attributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: email,
      }),
    ];

    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        if (err.code === "UsernameExistsException") {
          console.log("User exist: ", email);

          const userPool = new CognitoUserPool(poolData);

          const authenticationData = {
            Username: email,
            Password: password,
          };
          const authenticationDetails = new AuthenticationDetails(
            authenticationData
          );

          const userData = {
            Username: email,
            Pool: userPool,
          };

          const cognitoUser = new CognitoUser(userData);
          cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (session) => {
              console.log("User exists. Session:", session);
              setIsUserExists(true);
            },
            onFailure: (err) => {
              console.error(err);
              if (err.code === "UserNotConfirmedException") {
                setShowConfirmationCode(true);
                setButtonText("Confirm Code");
                setIsUserExists(false);
              } else {
                navigate("/forgot-password");
              }
            },
          });
        }
        return;
      }

      const cognitoUser = result.user;
      console.log("user registered:", cognitoUser);

      if (result.userConfirmed === false) {
        setShowConfirmationCode(true);
        setButtonText("Confirm Code");
        setIsUserExists(false);
      }
    });
  };

  const TicketIcon = () => (
    <img
      className="deleteicon"
      src="/Registration/delete.png"
      alt="Delete Icon"
      width="24"
      height="24"
    />
  );

  const handleConfirmCode = () => {
    const poolData = {
      UserPoolId: "eu-north-1_fiC62d8OP",
      ClientId: "715e0qqr8h55p6qi3onn397hku",
    };

    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(
      confirmationCode,
      true,
      (confirmationErr, confirmationResult) => {
        if (confirmationErr) {
          console.error("Confirmation error:", confirmationErr);
          return;
        }
        setRegistrationSuccess(true);

        console.log("user registration confirmed:", confirmationResult);
      }
    );
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);

    const errors = {
      minLength: password.length >= minLength,
      hasNumber,
      hasSpecialChar,
      hasUppercase,
      hasLowercase,
    };

    return errors;
  };

  const [passwordErrors, setPasswordErrors] = useState({
    minLength: true,
    hasNumber: true,
    hasSpecialChar: true,
    hasUppercase: true,
    hasLowercase: true,
  });

  const handlePasswordChange = (password) => {
    const errors = validatePassword(password);
    setPasswordErrors(errors);
  };

  const handleResendCode = () => {
    const poolData = {
      UserPoolId: "eu-north-1_fiC62d8OP",
      ClientId: "715e0qqr8h55p6qi3onn397hku",
    };

    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        console.error("Error resending confirmation code:", err);
        setResendMessage(
          "Error resending confirmation code. Please try again."
        );
      } else {
        console.log("Confirmation code resent successfully:", result);
        setResendMessage("Confirmation code sent successfully!");
        setResendLinkVisible(false);

        let timer = 15;
        const intervalId = setInterval(() => {
          timer--;
          setResendTimer(timer);

          if (timer === 0) {
            setResendLinkVisible(true);
            setResendMessage("");
            setResendTimer(15);
            clearInterval(intervalId);
          }
        }, 1000);
      }
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="registrationform">
      {!showConfirmationCode && (
        <>
          <div className="group">
            <div className="label">Email Address</div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="rectangle"
            />
          </div>

          <div className="overal-1">
            <div className="group">
              <div className="label">Password</div>
              <div className="input-container">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    handlePasswordChange(e.target.value);
                  }}
                  className="rectangle"
                />
                <img
                  className="icon-olor-instance"
                  alt="Icon olor"
                  src="icon-olor-1.svg"
                  onClick={handleTogglePassword}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>

          <div className="password-errors">
            {!passwordErrors.minLength && (
              <p className="error-message">
                <TicketIcon />
                Password must be at least 8 characters long.
              </p>
            )}
            {!passwordErrors.hasNumber && (
              <p className="error-message">
                <TicketIcon />
                Password must contain at least 1 number.
              </p>
            )}
            {!passwordErrors.hasSpecialChar && (
              <p className="error-message">
                <TicketIcon />
                Password must contain at least 1 special character.
              </p>
            )}
            {!passwordErrors.hasUppercase && (
              <p className="error-message">
                <TicketIcon />
                Password must contain at least 1 uppercase letter.
              </p>
            )}
            {!passwordErrors.hasLowercase && (
              <p className="error-message">
                <TicketIcon />
                Password must contain at least 1 lowercase letter.
              </p>
            )}
          </div>
        </>
      )}

      {showConfirmationCode && (
        <div className="group">
          <div className="label">Confirmation Code</div>
          <input
            type="text"
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
            className="rectangle"
          />
          {resendLinkVisible && (
            <button className="button-link" onClick={handleResendCode}>
              Resend Confirmation Code
            </button>
          )}
          {resendMessage && <p className="resend-message">{resendMessage}</p>}
          {!resendLinkVisible && (
            <p className="resend-timer-message">
              Resend link will be available in {resendTimer} seconds.
            </p>
          )}
        </div>
      )}

      {isUserExists && (
        <div className="isUserExits-message">
          User already exists with this email!{" "}
          <span>
            <NavLink to="/login">Login here</NavLink>
          </span>
        </div>
      )}

      {registrationSuccess && (
        <div className="success-message">
          User registration successful and verified!{" "}
          <span>
            <NavLink to="/login">Login here</NavLink>
          </span>
        </div>
      )}

      <button
        onClick={showConfirmationCode ? handleConfirmCode : handleSignUp}
        className="frame"
      >
        <div className="text-wrapper">{buttonText}</div>
      </button>
    </div>
  );
};

export default RegistrationForm;
