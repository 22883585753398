import "./LoginLogo.css";
function LoginLogo() {
  return (
    <img
      alt="swiirl-logo"
      className="swiirl-logo"
      src="/Login/swiirl-black-aqua-dot-1-1.png"
    />
  );
}
export default LoginLogo;
