// authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    token: null, // Add token property
    decryptedToken: null,
    email: null,
  },
  reducers: {
    login: (state, action) => {
      // Update the state with the provided properties
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.decryptedToken = action.payload.decryptedToken;
      state.email = action.payload.email;
    },
    logout: (state) => {
      // Clear the properties on logout
      state.isAuthenticated = false;
      state.token = null;
      state.decryptedToken = null;
      state.email = null;
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
