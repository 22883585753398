// TokenService.js
import { jwtDecode } from "jwt-decode";

const CLIENT_ID = "657c4dfd2027d004dfea9f0c"; // Replace with your actual client ID
const AUTH_URL =
  "https://dev.backend.drops.thenftbrewery.com/api/frontend/auth/token";

export const getToken = async (idToken) => {
  try {
    // Make the API call to get the token
    const response = await fetch(`${AUTH_URL}/${CLIENT_ID}`, {
      method: "GET",
    });

    if (!response.ok) {
      console.error("Failed to get token:", response);
      throw new Error("Failed to get token");
    }

    const data = await response.json();

    // Check if the status is 200
    if (data.status === 200 && data.token) {
      // Decrypt the token (you need to implement the decryptToken function)
      const { rawToken, decryptedToken } = decryptToken(data.token);

      // Return an object with both the raw token and the decrypted token
      return Promise.resolve({ rawToken, decryptedToken });
    } else {
      console.error("Failed to get token:", data);
      throw new Error("Failed to get token");
    }
  } catch (error) {
    console.error("Error while getting token:", error);
    throw error;
  }
};

const decryptToken = (token) => {
  // Implement your decryption logic here
  // You can use jwtDecode library or any other method
  const decryptedToken = jwtDecode(token);

  // Return an object with both the raw token and the decrypted token
  return { rawToken: token, decryptedToken };
};

// WalletService.js

export const createWallet = async (token, email) => {
  try {
    const createWalletEndpoint =
      "https://dev.backend.drops.thenftbrewery.com/api/frontend/wallet/createWallet";
    const createWalletBody = {
      email: `${email}`,
    };

    const response = await fetch(createWalletEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(createWalletBody),
    });

    if (response.ok) {
      const responseData = await response.json();

      if (responseData.status === 200) {
        // Successfully created wallet
        console.log("Wallet created successfully");
        console.log("Wallet Address:", responseData.walletAddress);

        // Return relevant data
        return {
          success: true,
          walletAddress: responseData.walletAddress,
        };
      } else {
        // Handle error creating wallet
        console.error("Error creating wallet:", responseData.message);
        return { error: responseData.message };
      }
    } else {
      console.error("Unexpected error:", response);
      // Handle unexpected errors
      return { error: "Unexpected error" };
    }
  } catch (error) {
    console.error("Unexpected error:", error);
    // Handle unexpected errors
    return { error: "Unexpected error" };
  }
};

export const fetchGalleryData = async (email, token) => {
  try {
    const page = 1; // Replace with the desired page number
    const limit = 10000; // Replace with the desired limit

    const response = await fetch(
      `https://dev.backend.drops.thenftbrewery.com/api/frontend/getPremintAssetByEmail/${email}/${page}/${limit}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();
    return data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const fetchWalletAddress = async (email, token) => {
  try {
    const response = await fetch(
      `https://dev.backend.drops.thenftbrewery.com/api/frontend/wallet/getWallet/${email}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data.wallet_address;
    } else {
      console.error("Failed to fetch wallet address");
      return null;
    }
  } catch (error) {
    console.error("Error fetching wallet address:", error);
    throw error;
  }
};
