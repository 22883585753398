import "./GalleryCard.css";

import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";

function GalleryCard(props) {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleShareClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const GoToDetailsView = async (tokenId, imageUrl) => {
    if (!tokenId) {
      // Handle missing or invalid tokenId
      console.error("Invalid tokenId");
      return;
    }

    try {
      navigate(`/nft/${tokenId}`, { state: { imageUrl } });
    } catch (error) {
      // Handle navigation error
      console.error("Navigation error:", error);
    }
  };

  //const [value, setValue] = useState(props.name);
  const [copied, setCopied] = useState(false);

  const otherCopy = () => setCopied(true);

  const downloadImage = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      // Create a download link
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = props.tokenId;

      // Append the link to the body and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Remove the link from the body
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  return (
    <div className="custom-card">
      <div className="image-holder">
        <img
          src={props.imageurl}
          alt=""
          className="css-image-1"
          onClick={() => GoToDetailsView(props.tokenId, props.imageurl)}
        />
      </div>
      <div className="card-body">
        <div className="card-row-1">
          <div className="text-wrapper-7">{props.tokenId}</div>
          <div className="text-wrapper-8">{props.name}</div>
          <div className="text-wrapper-9">Token ID</div>
          <div className="text-wrapper-10">Name</div>
          <img className="line" alt="Line" src="/Gallery/line-1-7.svg"></img>
          <img className="line-2" alt="Line" src="/Gallery/line-2-7.svg"></img>
          <div className="card-row-2">
            <div className="text-wrapper-13">Select</div>
            <input
              type="checkbox"
              className="rectangle-gallery"
              checked={props.isChecked}
              onChange={() => props.onCheckboxChange(!props.isChecked)}
            ></input>
          </div>
          <img
            className="send-message"
            alt="Send message"
            src="/Gallery/Import_light.svg"
            onClick={() => downloadImage(props.imageurl)}
          ></img>
          <img
            className="bi-share-fill"
            alt="Bi share fill"
            src="/Gallery/bi-share-fill.svg"
            onClick={handleShareClick}
          ></img>
        </div>
      </div>
      {/* Share Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Share Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            value={props.imageurl}
            className="rectangle-copy"
            readOnly
          />
          <CopyToClipboard onCopy={otherCopy} text={props.imageurl}>
            <img
              className="copy-icon"
              alt="Copy Link"
              src={
                copied
                  ? "/Gallery/copy-icon-green.svg"
                  : "/Gallery/copy-icon.svg"
              }
              onClick={handleShareClick}
            ></img>
          </CopyToClipboard>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default GalleryCard;
