import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { forgotPassword } from "../../Services/AwsService"; // Import the AWS service

import LoginLogo from "../../Components/LoginLogo";
import Description from "../../Components/Description";
import "./ForgotPasswordForm.css";

const ForgotPasswordForm = () => {
  const [username, setUsername] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    try {
      await forgotPassword(username);
      setErrorMessage("");
      setSuccessMessage(
        "Verification code sent successfully. Check your email."
      );
      // Inside handleForgotPassword method
      navigate(`/reset-password?username=${username}`);
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage(`Error: ${error.message || JSON.stringify(error)}`);
    }
  };

  return (
    <div className="limitter">
      <div className="custom-container">
        <div className="login-content">
          <div className="nav-header">
            <div className="logo-holder">
              <LoginLogo />
            </div>
          </div>

          <Description />

          <div className="forgot-password-form">
            <div className="group">
              <div className="label">Enter your email:</div>
              <input
                type="text"
                className="rectangle"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="back-to-link-div">
              <Link to="/login" className="back-to-login-link">
                Back to Login
              </Link>
            </div>
            <button onClick={handleForgotPassword} className="frame-1">
              <div className="text-wrapper">Send Verification Code</div>
            </button>
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            {successMessage && (
              <p style={{ color: "green" }}>{successMessage}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
