// ConfirmationCode.js
import React, { useState, useEffect } from "react";
import {
  confirmRegistration,
  resendConfirmationCode,
} from "../Services/AwsService";
import { createWallet } from "../Services/nftbreweryService";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../Services/nftbreweryService"; // Import getToken and login
import { login } from "../authSlice";
import "./ConfirmationCodeForm.css";

function ConfirmationCodeForm({ onCallback, userName }) {
  const emailLabel = "E-mail Address";
  const buttonText = "Confirm Code";
  const [username, setUsername] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [resendLinkVisible, setResendLinkVisible] = useState(true);
  const [resendMessage, setResendMessage] = useState("");
  const [resendTimer, setResendTimer] = useState(15);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registrationFailure, setRegistrationFailure] = useState(false);
  //const [walletSuccess, setWalletSuccess] = useState(false);
  const [walletFailure, setWalletFailure] = useState(false);
  //const [walletAddress, setWalletAddress] = useState("");

  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.auth.token);

  useEffect(() => {
    // Fetch the token when the component mounts
    const fetchToken = async () => {
      try {
        const { rawToken, decryptedToken } = await getToken();

        if (
          decryptedToken !== null &&
          decryptedToken.frontend_domain_url === "https://demo.swiirl.com"
        ) {
          dispatch(
            login({
              token: rawToken,
              decryptedToken: decryptedToken,
            })
          );
        } else {
          console.error("Token is empty or domain is incorrect");
        }
      } catch (tokenError) {
        console.error("Error while getting or decrypting token:", tokenError);
      }
    };

    fetchToken(); // Call the function to fetch the token

    // Set the username state only if userName is provided
    if (userName) {
      setUsername(userName);
    }
  }, [dispatch, userName]);

  const handleLogin = () => {
    // Additional logic if needed
    onCallback();
  };

  const handleConfirmCode = async () => {
    try {
      await confirmRegistration(username, confirmationCode);
      setRegistrationSuccess(true);

      console.log(authToken); // Now, authToken should have a valid value

      // Create wallet
      const walletResult = await createWallet(authToken, userName);

      if (walletResult.success) {
        //setWalletSuccess(true);
        //setWalletAddress(walletResult.walletAddress);
        console.log("Wallet: ", walletResult);
      } else {
        setWalletFailure(true);
        console.log("Wallet creation failed", walletResult.error);
      }
    } catch (confirmationError) {
      console.error("Error confirming registration:", confirmationError);
      setRegistrationFailure(true);
    }
  };

  const handleResendCode = async () => {
    try {
      const message = await resendConfirmationCode(username);
      setResendMessage(message);

      let timer = 15;
      const intervalId = setInterval(() => {
        timer--;
        setResendTimer(timer);

        if (timer === 0) {
          setResendLinkVisible(true);
          setResendMessage("");
          setResendTimer(15);
          clearInterval(intervalId);
        }
      }, 1000);
    } catch (resendError) {
      console.error("Error while resending confirmation code:", resendError);
      setResendMessage(resendError);
    }
  };

  return (
    <div className="group">
      <div className="group">
        <div className="label">{emailLabel}</div>
        <input
          type="text"
          className="rectangle"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        ></input>
      </div>
      <div className="group">
        <div className="label">Confirmation Code</div>
        <input
          type="text"
          value={confirmationCode}
          onChange={(e) => setConfirmationCode(e.target.value)}
          className="rectangle"
        />
      </div>
      {resendLinkVisible && (
        <div className="forgot-password-link">
          <button className="button-link" onClick={handleResendCode}>
            Resend Confirmation Code
          </button>
        </div>
      )}
      {resendMessage && <p className="resend-message">{resendMessage}</p>}
      {!resendLinkVisible && (
        <p className="resend-timer-message">
          Resend link will be available in {resendTimer} seconds.
        </p>
      )}
      {registrationSuccess && (
        <div className="success-message">
          User registration successful and verified!{" "}
          <button className="button-link" onClick={handleLogin}>
            Login here
          </button>
        </div>
      )}
      {registrationFailure && (
        <div className="success-message">
          User registration failed! Try registering again.
        </div>
      )}
      {/*
      {walletSuccess && (
        <div className="success-message">
          Wallet {walletAddress} created successful!
        </div>
      )}
      */}
      {walletFailure && (
        <div className="success-message">
          Wallet created failed! Reach out to adminstrator.
        </div>
      )}
      <button onClick={handleConfirmCode} className="frame">
        <div className="text-wrapper">{buttonText}</div>
      </button>
    </div>
  );
}

export default ConfirmationCodeForm;
