import "bootstrap/dist/css/bootstrap.min.css";

import "./Login.css";

import Description from "../../Components/Description";
import LoginLogo from "../../Components/LoginLogo";
import LoginFooter from "../../Components/LoginFooter";
import LoginForm from "../../Components/LoginForm";

function LoginBasic() {
  return (
    <div className="limitter">
      <div className="custom-container">
        <div className="login-content">
          <div className="nav-header">
            <div className="logo-holder">
              <LoginLogo />
            </div>
          </div>

          <Description />
          <LoginForm />
          <LoginFooter page="login" />
        </div>
      </div>
    </div>
  );
}

export default LoginBasic;
