import "./Description.css";
function Description() {
  const headline = "Artfully funding the Future";
  const subText =
    "Make a difference today by signing up for Swiirl and participating in our mission to create a more equitable world.";
  return (
    <div className="description">
      <div className="headline-text">{headline}</div>
      <p className="subtext">{subText}</p>
    </div>
  );
}

export default Description;
