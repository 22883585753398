import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import Gallery from "./Screens/Gallery/Gallery";
import Registration from "./Screens/Registration/Registration";
import Login from "./Screens/Login/Login";
import Settings from "./Screens/Settings/Settings";
import Campaigns from "./Screens/Campaigns/Campaigns";
import Analytics from "./Screens/Analytics/Analytics";
import ForgotPasswordForm from "./Screens/ForgotPasswordForm/ForgotPasswordForm";
import ResetPasswordForm from "./Screens/ResetPasswordForm/ResetPasswordForm";
import Nft from "./Screens/Nft/Nft";

const store = configureStore({
  reducer: {
    auth: authSlice,
  },
});

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/campaigns" element={<Campaigns />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/forgot-password" element={<ForgotPasswordForm />} />
          <Route path="/reset-password" element={<ResetPasswordForm />} />
          <Route path="/nft/:id" element={<Nft />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
